import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';
import { GatsbyImage } from 'gatsby-plugin-image';
import TwHero from '../components/TwHero';

const About = (props) => {
  const markdown = props.data.services.edges;
  const heroBackground = props.data.heroBackground;
  const areas = props.data.areas.edges;
  const json = props.data.allFeaturesJson.edges;
  return (
    <Layout bodyClass="page-home">
      <SEO
        title="About LaSalle | Furnace Repair, Air Conditioning Service, and Intallation"
        description="air conditioning, furncace, and heater: tune-ups, installations, and repair. Emergency HVAC services available."
      />
      <TwHero
        bg={heroBackground}
        headline="Minnesota Residential Furnace / Air Conditioning (AC) Service, Installation, and Repair"
        text="Family-owned HVAC business operated out of our Burnsville, MN headquarters. We have a small team that will do whatever it takes to earn your business. AC or Furnace troubles? We can help!"
        btnURL="/hvac-service-installation-repair-scheduling"
        btnTxt="Schedule Home HVAC Service"
        featured={[
          {
            name: 'HVAC Housecall',
            color: 'red-800',
            href: '/hvac-service-installation-repair-scheduling',
            description: `HVAC units don't sleep! We offer emergency 24/7 emergency repair, inspection, and installation. If your AC or Furnace is doing anything out of the ordinary, let us know!`,
            icon: '/2022/icons/minnesota-hvac-service-repair-installation.svg',
            btnTxt: 'Get HVAC Support',
          },
          {
            name: 'Furnace Installation & Repairs',
            href: '/hvac-service-installation-repair-scheduling',
            color: 'orange-500',
            description: `Need a new furnace installation? We've got you covered. As a trusted Bryant® HVAC installation team, we've got you covered with the best HVAC units we've found to date. `,
            icon: '/2022/icons/minnesota-furnace-hvac-service-installation-repair.svg',
            btnTxt: 'Schedule Furnace or Heater Service',
          },
          {
            name: 'AC Installation, Service, Repair',
            href: '/hvac-service-installation-repair-scheduling',
            color: 'blue-500',
            description: `Our 3-month window of NEEDING a functioning air conditioning unit is upon us. Coincidentally this is also when they typically decide to die. If you either don't have one or have one on the fritz, get in touch. We can help with AC installations, inspections, and repairs.`,
            icon: '/2022/icons/best-ac-air-conditioning-service-repair-minnesota.svg',
            btnTxt: 'Schedule AC Service or Installation',
          },
        ]}
      />
      <div className="bg-blue-500 p-20">
        <div className="row justify-content-start">
          <h2 className="text-3xl font-extrabold text-white sm:text-6xl">
            Minnesota HVAC Service & Repair Areas
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-20 py-20">
            {areas.map((edge, i) => (
              <div key={`${i}-${edge.node.frontmatter.path}`} className="">
                <h2 className="text-4xl font-extrabold text-white">
                  <Link to={edge.node.frontmatter.path}>
                    {edge.node.frontmatter.title}
                  </Link>
                </h2>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-red-800 p-20">
        <div className="row justify-content-start">
          <h2 className="text-3xl font-extrabold text-white sm:text-6xl">
            Our HVAC Services
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10 py-10">
            {markdown.map((edge, i) => (
              <div key={`${i}-${edge.node.frontmatter.path}`}>
                <h2 className="text-4xl font-extrabold text-white">
                  <Link to={edge.node.frontmatter.path}>
                    {edge.node.frontmatter.title}
                  </Link>
                </h2>
                <h4 className="text-red-200 font-bold">{edge.node.excerpt}</h4>
              </div>
            ))}
          </div>
          <div className="col-12 text-center">
            <Link
              className="inline-flex items-center px-6 py-3 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              to="/residential-hvac"
            >
              View All Services
            </Link>
          </div>
        </div>
      </div>

      <div className="container22 pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="call-options  row justify-content-center">
          <div className="col-12">
            <h4 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Need HVAC Service, Repair, or Installation?
            </h4>
            <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
              We've got a few ways for you to reach out to us about your air
              conditioning, furnace, heater, or general HVAC needs:
            </p>
          </div>
          <div className="grid grid-cols-3 gap-20 p-20 ">
            {json.map((edge, i) => (
              <div key={`${i}-${edge.node.id}`}>
                <a href={edge.node.url}>
                  <div className=" bg-gray-300 grid items-center justify-center rounded-lg xl:px-10 xl:text-left aspect-square">
                    <div>
                      {edge.node.image && (
                        <div className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56 bg-gray-100 grid items-center justify-center mb-10">
                          <img
                            src={withPrefix(edge.node.image)}
                            className="w-32 h-32"
                          />
                        </div>
                      )}
                      <h2 className="text-3xl font-extrabold text-gray-700 tracking-tight sm:text-4xl text-center">
                        {edge.node.title}
                      </h2>
                      <div className="text-gray-500 text-center">
                        {edge.node.description}
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="container22 pt-2">
          <Call button />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    services: allMdx(
      filter: { fileAbsolutePath: { regex: "/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    areas: allMdx(
      filter: { fileAbsolutePath: { regex: "/areas/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    heroBackground: file(
      relativePath: { eq: "2022/home-air-conditioning-repair-service-mn.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image
          url
        }
      }
    }
  }
`;

export default About;
